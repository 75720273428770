import React, { useEffect, useRef } from "react";
import { MdOutlineDone } from "react-icons/md";

const StepbyStepLearn = ({ video }) => {
  const vidRef = useRef();
  useEffect(() => {
    vidRef.current.play();
  }, []);
  return (
    <div>
      <div className="flex flex-col lg:flex-row justify-between items-start py-5 lg:py-20 bg-[#06111A]">
        <div className="w-full lg:w-1/2 flex justify-center">
          <video autoPlay muted loop ref={vidRef}>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col ms-5 items-start">
          <h1 className="uppercase text-xl lg:text-3xl text-white font-bold mb-2 lg:mb-8">
            step-by-step learning
          </h1>
          <p className="text-md lg:text-2xl leading-8">
            You will get access to
            <span className="font-bold text-white ms-2">100+ video courses</span> and
            well-
            <br className="hidden lg:flex" />
            structured tutorials covering everything from the
            <br className="hidden lg:flex" />
            fundamental of modern business to
            <span className="text-white font-bold ms-2">
              niche money <br className="hidden lg:flex"/>strategies.
            </span>
          </p>
          <div className=" w-11/12 lg:w-4/5 rounded-xl bg-[#141B23] mt-10">
            <div className="flex flex-row items-center py-3">
              <MdOutlineDone className="text-[#FFA544] w-8 h-8 ms-5 me-5 items-center" />
              <h1 className="text-md lg:text-xl text-white font-bold">
                Easy to follow program for financial success
              </h1>
            </div>
          </div>
          <div className="w-11/12 lg:w-4/5 rounded-xl bg-[#141B23] mt-4">
            <div className="flex flex-row items-center py-3">
              <MdOutlineDone className="text-[#FFA544] w-8 h-8 ms-5 me-5 items-center" />
              <h1 className="text-md lg:text-xl text-[#9B9EA1]">New high income skill</h1>
            </div>
          </div>
          <div className="w-11/12 lg:w-4/5 rounded-xl bg-[#141B23] mt-4">
            <div className="flex flex-row items-center py-3">
              <MdOutlineDone className="text-[#FFA544] w-8 h-8 ms-5 me-5 items-center" />
              <h1 className="text-md lg:text-xl text-[#9B9EA1]">
                Hyper advanced learning application
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepbyStepLearn;
