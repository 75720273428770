import React, { useEffect, useRef } from "react";

const DailyLiveSesson = ({ video }) => {
  const vidRef = useRef();
  useEffect(() => {
    vidRef.current.play();
  }, []);
  return (
    <div className="mx-0 lg:mx-10">
      <div className="flex flex-col lg:flex-row-reverse justify-between items-start py-5 lg:py-20 bg-[#06111A]">
        <div className="w-full lg:w-1/2 flex justify-center">
          <video autoPlay muted loop ref={vidRef}>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col ms-3 lg:ms-5 items-start">
          <h1 className="uppercase text-xl lg:text-3xl text-white font-bold mb-4">
            daily live session with millionier <br className="hidden lg:flex"/> coaches
          </h1>
          <p className=" text-md lg:text-2xl leading-8 text-[#9B9EA1]">
            Each of our
            <span className="text-white">
              professors have made over $1M profit
            </span>
            <br />
            using the method they teach inside our curriculum. <br />
          </p>
          <p className="text-md lg:text-2xl leading-8 text-[#9B9EA1] my-5">
            They are hyper-motivated,
            <span className="text-white font-bold">
              experienced professionals
            </span>
            who <br /> will provide you with organized coursework, daily new
            lessons <br /> and constant{" "}
            <span className="font-bold text-white">
              mentoring throughout your entrepreneurship.
            </span>
          </p>
          <p className="text-md lg:text-2xl leading-8 text-[#9B9EA1] my-5">
            From learning how to make your first dollar,{" "}
            <span className="font-bold text-white">
              to scaling <br className="hidden lg:flex"/> multi million dollar business, THE REAL
              WORLD is <br /> unmatched.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DailyLiveSesson;
