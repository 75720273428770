import React, { useEffect, useRef } from "react";
import { MdOutlineDone } from "react-icons/md";

const ExclusiveCommunity = ({ video }) => {
  const vidRef = useRef();
  useEffect(() => {
    vidRef.current.play();
  }, []);
  return (
    <div className="mx-2 lg:mx-10">
      <div className="flex flex-col lg:flex-row justify-between items-start py-5 lg:py-20 bg-[#06111A]">
        <div className="w-full lg:w-1/2 flex justify-center">
          <video autoPlay muted loop ref={vidRef}>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col ms-1 lg:ms-5 items-start">
          <h1 className="uppercase text-xl lg:text-3xl text-white font-bold mb-8">
            n exclusive community with 113k+ like <br className="hidden lg:flex"/> minded students
          </h1>
          <p className="text-md lg:text-2xl leading-8">
            Our online community is a supportive, high-focus <br /> environment.
            Everyone is on the same mission: acquiring an <br /> abundance of
            wealth
          </p>
          <div className=" w-full lg:w-4/5 rounded-xl bg-[#141B23] mt-10">
            <div className="flex flex-row items-center py-3">
              <MdOutlineDone className="text-[#FFA544] w-8 h-8 ms-5 me-5 items-center" />
              <h1 className="text-md lg:text-xl text-[#9B9EA1] font-bold">
                Network with{" "}
                <span className="font-bold text-white">
                  113.000+ people on the same mission
                </span>
              </h1>
            </div>
          </div>
          <div className=" w-full lg:w-4/5 rounded-xl bg-[#141B23] mt-4">
            <div className="flex flex-row items-center py-3">
              <MdOutlineDone className="text-[#FFA544] w-8 h-8 ms-5 me-5 items-center" />
              <h1 className="text-md lg:text-xl text-[#9B9EA1]">
                make <span className="text-white">like minded friend</span> on
                your financial journey
              </h1>
            </div>
          </div>
          <div className=" w-full lg:w-4/5 rounded-xl bg-[#141B23] mt-4">
            <div className="flex flex-row items-center py-3">
              <MdOutlineDone className="text-[#FFA544] w-8 h-8 ms-5 me-5 items-center" />
              <h1 className="text-md lg:text-xl text-[#9B9EA1]">
                <span className="text-white font-bold">
                  Celebrate your wins
                </span>
                with people who understand your ambition
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExclusiveCommunity;
