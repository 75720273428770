import React from 'react';
import bannar from "../../assets/Images/blurbannar.avif";

const AchiveTheGoal = () => {
    return (
        <div className=" flex flex-col lg:flex-row ">
        <div className="w-full lg:w-1/2 flex flex-col items-center justify-around">
          <h1 className="uppercase text-xl lg:text-4xl text-white tracking-wide	mb-10">
            achive your <span className="font-extrabold">goals</span>
          </h1>
          <p className=" mx-2 lg:mx-10 text-2xl tracking-wide text-[#9B9EA1] mb-5">
            <span className="text-white font-bold">
              Money-making is a skill
            </span>
            . Like every other skill it can be <br className='hidden lg:flex'/> learned, and the speed
            at which it is learned depends on <br className='hidden lg:flex'/> your coaches and the
            learning environment you are <br className='hidden lg:flex'/> taught in.
          </p>
          <p className="mx-2 lg:mx-10 text-2xl tracking-wide text-[#9B9EA1] mb-5">
            <span className="text-white font-bold">
              Our coaches know the business models they teach,
            </span>
            <br className='hidden lg:flex'/>
            they know what it takes to be profitable, and they are the <br className='hidden lg:flex'/>{" "}
            first to identify and utilize new disruptive technologies and{" "}
            <br className='hidden lg:flex'/> strategies whenever they appear.
          </p>
          <p className="mx-2 lg:mx-10 text-2xl tracking-wide text-[#9B9EA1]">
            <span className="text-white font-bold">Money University</span> is
            the ultimate all-in-one learning platform guiding you from making
            your first dollar online to scaling into a multi-million dollar
            business.
          </p>
          <p className="mx-2 lg:mx-10 text-2xl tracking-wide text-[#9B9EA1] mb-5 lg:mb-0">
            <span className="text-[#FECF23] font-bold me-2">
              There is no better place on the planet
            </span>
            to learn how to make money online today
          </p>
        </div>
        <img src={bannar} alt="" />
      </div>
    );
};

export default AchiveTheGoal;