import React from "react";

const InterviewCard = ({title, youtube, subtitle, revinew}) => {
  return (
    <div className="card lg:card-side bg-[#131C23] shadow-xl mx-2 lg:mx-10 my-10 lg:my-10 ">
      <div className="card-body p-2 lg:p-10">
        <h1 className="text-[#FFA544] uppercase">{title}</h1>
        <h1 className="text-white text-4xl font-extrabold">{subtitle}</h1>
        <h1 className="text-[#9B9EA1] text-2xl my-6">
          New revinew : ${revinew}/month
        </h1>
        <h1 className="w-96 text-[#9B9EA1]">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa earum
          praesentium odio repudiandae! Saepe aspernatur dolorum sint odio ut
          nostrum rem a dolore beatae inventore quia architecto veritatis,
          incidunt vitae!
        </h1>
      </div>
      <figure>
        <iframe
          className="h-[320px] lg:h-[370px] w-[700px]"
          src={youtube}
          width="100%"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </figure>
    </div>
  );
};

export default InterviewCard;
