import React from "react";

const YoutubeVideo = () => {
  return (
    <iframe
      className="h-[300px] lg:h-[500px] w-full lg:w-3/4"
      src="https://www.youtube.com/embed/umtVtf5o0-o"
      width="100%"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  );
};

export default YoutubeVideo;
